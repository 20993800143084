import React from 'react';
import { MktMarketCard as MarketCard } from '@securitize/reactjs-mkt-ui';

interface MktMarketCardProps {
  title: string;
  subtitle: string;
  description?: string;
  tableValue: { key: string; value: string }[];
  image: string;
  tags: { children: string }[];
  backgroundImage?: string;
}

export const MktMarkedCard: React.FC<MktMarketCardProps> = ({
  image,
  subtitle,
  tableValue,
  title,
  description,
  tags,
  backgroundImage,
}) => {
  return (
    <div className="market-card">
      <MarketCard
        tags={tags}
        logo={image}
        subtitle={subtitle}
        tableValues={tableValue}
        title={title}
        description={description}
        backgroundImage={backgroundImage}
      />
    </div>
  );
};
